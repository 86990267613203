<template>
  <div class="About">
    <div>
        <img src="@/assets/yibo/4/蒙版组 9@2x.png" alt="" style="width:100%;height:auto;">
    </div>
    <div class="About_content">
      <div class="moduleOne">
            <div style="padding-top:20px;">
                <div class="conT2">
                    <div class="title">公司介绍</div>
                    <div class="subtitle">打造智慧停车全生态系统运用</div>
                </div>
                <div>
                  <p style="text-align: left;text-indent: 1cm">珠海怡泊科技有限公司成立于2020年，坐落于广东省珠海市高新区唐家湾镇香山路88号2栋14层1406-02室，业务遍布全国各地，主要研发、销售、服务于智慧城市交通基础设施设备、提供整套停车解决方案。 本公司依托物联网、云计算、大数据等尖端技术，致力于发展智慧社区、停车场智慧化管理等创新产品。 </p>
                  <p style="text-align: left;text-indent: 1cm">公司秉持以服务至上，合作共享、便捷实用的服务理念，为社区、商城、学校，写字楼，政企单位等提供智慧化解决方案。公司拥有一支技术实力雄厚、职业有素、志向远大的团队。 </p>
                  <p style="text-align: left;text-indent: 1cm">在当前快速发展的移动物联时代，以专业、创新、引领、智慧的发展理念，用心解决用户最迫切、最实际的需求，以优质的服务，先进的技术为用户提供完美的服务。公司不以利益为唯一目标，更重视长远的社会责任，致力打造基于5G物联网时代的快捷生活，使用户亲身感受到5G物联网在居家、出行、消费、生活、安全等方面提供便捷服务。</p>
                </div>
            </div>
        </div>
        <div class="moduleTwo">
            <div style="padding-top:20px;">
                <div class="conT2">
                    <div class="title">公司优势</div>
                    <div class="subtitle">领先软硬件配置 完整的运维系统</div>
                </div>
                <div>
                    <div style="width:100%;">
                        <img src="@/assets/tongtong/3/组 2247@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="moduleThree">
            <div style="padding-top:20px;">
                <div class="conT2">
                <div class="title">产品组成</div>
                <div class="subtitle">智慧创新 未来可期</div>
                </div>
                <div>
                    <div style="width:100%;">
                        <img src="@/assets/tongtong/3/组 469@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="content2">
                <div class="conT2">
                    <div class="title">企业荣耀</div>
                    <div class="subtitle">智慧停车 · 引领未来</div>
                </div>
                <div class="content2_img">
                    <div style="width:100%;">
                        <img src="@/assets/tongtong/3/组 2246@2x.png" alt="" style="width:100%;height:auto;">
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main_content">
                <div class="conT2">
                    <div class="title">联系方式</div>
                    <div class="subtitle">智慧城市 · 塑造未来</div>
                </div>
                <div class="concat">
                    <div class="concat_one">
                        <img src="@/assets/tongtong/3/Telephone@2x.png" alt="" width="30" height="30">
                        400-8633-819
                    </div>
                    <div class="concat_one">
                        <img src="@/assets/tongtong/3/mailbox@2x.png" alt="" width="30" height="30">
                        3162334924@qq.com
                    </div>
                    <div class="concat_one">
                        <img src="@/assets/tongtong/3/address@2x.png" alt="" width="30" height="30">
                        中山市坦南创客园B栋508
                    </div>
                    
                </div>
            </div>
        </div>
  </div>
</div>
</template>

<script>
import about from '@/api/about.js'
export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data(){
    return {
        introd: {}
    }
  },
  created(){
    // this.initIntrod()
  },
  methods:{
    initIntrod(){
        about.Query({}).then(resp=>{
            if(resp.data.code == 200){
                console.log(resp.data.data.data,"简介")
                this.introd = resp.data.data.data
            }
            
        })
    }
  }
  
};
</script>
<style scoped lang="less">
.conT2 {
  padding: 10px 0;
  margin: 50px 0;
  text-align: center;
  .title {
    font-size: 34px;
    font-weight: bold;
    color:#000000FF;
    height: 45px;
    line-height: 35px;
  }
  .subtitle {
    font-size: 14px;
    color:#999999FF;
    height: 19px;
    line-height: 35px;
  }
  .line {
    width: 30px;
    height: 20px;
    margin: 0 auto;
    border-bottom: 6px solid #287bffff;
  }
}
.About {
  background: #FFFFFFFF;
  .About_content {
    width: 60%;
    margin: 0 auto;
    .main {
      margin-bottom: 20px;
    //   background-color: red;
      margin: 0 auto;
      &_content {
        padding: 20px 0;
        &_text {
            div{
                text-indent: 1rem;
                font-size: 18px;
                color: rgba(51, 51, 51, 1);
                text-align: left;    
            }
        }
        .concat {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            font-weight: 400;
            &_one {
                margin-bottom: 30px;
                img{
                    vertical-align: middle;
                }
            }
        }
      }
    }
    .moduleThree {
        // width: 1200px;
        // width: 60%;
        // min-width: 1200px;
        margin: 0 auto;
    }
    .main2 {
      margin-bottom: 20px;
    //   width: 1200px;
    //   width: 60%;
    //   min-width: 1200px;
      margin: 0 auto;
      .content2 {
        .content2_img {
            display:flex;
            flex-wrap: wrap;
            .item {
                width:calc(100% / 2);
                img {
                    width:100%;
                    height:auto
                }
            }
        }
      }  
    }
  }
  
}
</style>
